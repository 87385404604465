<div class="modal-content app-content">
  <div class="modal-header align-items-center mb-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary link-btns mr-3" data-bs-toggle="modal" data-bs-target="#right" style="
            border-radius: 50% !important;
            color: #fff !important;
            background: #213a60 !important;
            border-color: #213a60;
          ">
        <i class="bi bi-pencil-fill"></i>
      </button>
      <h5 class="modal-title app-title"> {{ domain?.id ? 'Update' : 'Create new' }} domain</h5>
    </div>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body px-4" *ngIf="!onlyClusterShared">
    <form [formGroup]="domainForm">
      <div class="row px-4">
        <div class="col-md-12 mb-3">
          <div class="form-group">
            <label for="host">Host*</label>
            <input id="host" type="text" class="form-control" placeholder="*.cloudoor.com" autocomplete="off"
              [ngClass]="{'input-red': this.domainForm.get('host').invalid }"
              style="margin: 0px !important;height: 2.7rem !important;" formControlName="host">
            <div class="invalid" *ngIf="host.invalid && (host.dirty || host.touched)">
              <div *ngIf="host.errors?.required">
                Host is required.
              </div>
              <div *ngIf="host.errors?.pattern">
                Host is invalid.
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="clusterId">Scope</label>
            <div class="custom-checkbox">
              <label class="btn-cluster" [ngClass]="{active: scope == 'cluster'}" for="scope-cluster">Cluster</label>
              <input [disabled]="canCreateOnlyScopeEnvironment()" type="radio" (change)="onChangeScope($event)"
                name="scope" value="cluster" id="scope-cluster" autocomplete="off">

              <label class="btn-environment" [ngClass]="{active: scope == 'environment'}"
                for="scope-environment">Environment</label>
              <input [disabled]="canCreateOnlyScopeEnvironment()" type="radio" (change)="onChangeScope($event)"
                name="scope" value="environment" id="scope-environment" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="scope == 'cluster'">
          <div class="form-group">
            <label for="clusterId">Cluster* <i *ngIf="loaderCluster" class="fa fa-spinner fa-spin"></i></label>
            <app-select-search label="Cluster" placeholder="Choose a cluster" option-label="name" option-value="id"
              [disable]="loaderCluster" [filtering]="false" formArrayName="clusterId" [options]="clusters"
              formControlName="clusterId" (change)="onChangeCluster($event)" (filter)="onFilter($event)">
            </app-select-search>
          </div>
        </div>

        <div class="col-md-12 mb-3" *ngIf="scope == 'environment'">
          <div class="form-group">
            <label for="projectId">Project* <i *ngIf="loaderProject" class="fa fa-spinner fa-spin"></i></label>
            <app-select-search label="Project" placeholder="Choose a project" option-label="name" option-value="id"
              [disable]="loaderProject" [filtering]="false" [options]="projects" formControlName="projectId"
              (change)="onChangeProject($event)" (filter)="onFilter($event)">
            </app-select-search>
          </div>
        </div>

        <div class="col-md-12 my-3" *ngIf="scope == 'environment'">
          <div class="form-group">
            <label for="environmentId">Environment* <i *ngIf="loaderEnvironment"
                class="fa fa-spinner fa-spin"></i></label>
            <app-select-search label="Environment" placeholder="Choose an environment" option-label="name"
              option-value="id" [disable]="loaderEnvironment" [filtering]="false" [options]="environments"
              formControlName="environmentId" (change)="onChangeEnvironment($event)" (filter)="onFilter($event)">
            </app-select-search>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <div class="switch-on-off">
              <div class="text">Secure</div>
              <label class="switch ml-1">
                <input type="checkbox" (change)="onChangeIsSecure($event)" [checked]="!isunsecure" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <fieldset class="certificate" *ngIf="!isunsecure">
          <legend>Certificate</legend>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">Secret Name*</label>
                  <input id="name" type="text" class="form-control" placeholder="Secret name*" autocomplete="off"
                    formControlName="name">
                  <div class="invalid" *ngIf="name.invalid && (name.dirty || name.touched)">
                    <div *ngIf="name.errors?.required">
                      Name is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="namespace">Secret Namespace*</label>
                  <input id="namespace" type="text" class="form-control" placeholder="Secret Namespace*" autocomplete="off"
                    formControlName="namespace">
                  <div class="invalid" *ngIf="namespace.invalid && (namespace.dirty || namespace.touched)">
                    <div *ngIf="namespace.errors?.required">
                      Certificate Secret Namespace is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="row py-3 pt-4">
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-primary update-btn" (click)="onSubmit()"
            [disabled]="loading || (domainForm.invalid)">
            {{ domain?.id ? 'Update' : 'Create' }}
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="onlyClusterShared" class="text-center">
    <i class="fa fa-warning"></i> Permission denied
  </div>
</div>
