import { Component, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { App, Metadata } from 'src/app/data/model/apps';
import {
  Deployment,
  DeploymentSpecs,
  Env,
} from 'src/app/data/model/deployment';
import { Environment } from 'src/app/data/model/environment';
import { Project } from 'src/app/data/model/project';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { AppService } from 'src/app/modules/app/app.service';
import { DeploymentService } from 'src/app/modules/deploy/services/deployment.service';
import { ProjectService } from 'src/app/modules/onboard/services/project.service';
import {
  Type,
  Position,
  generateRandomString,
  ACCESS_MODES,
  TOLERATION_OPERATORS,
  TOLERATION_EFFETS,
  CAPABILITIES,
} from 'src/app/utils/const';
import { Domain, Registry } from 'src/app/utils/types';
import { cpuUnits, memoryUnits } from 'src/app/utils/unit';

@Component({
  selector: 'app-form-deployment',
  templateUrl: './form-deployment.component.html',
  styleUrls: ['./form-deployment.component.scss'],
})
export class FormDeploymentComponent implements OnInit {
  deploymentForm: FormGroup;

  @Input() deployment: Deployment;

  @Output() change: Deployment;

  projects: Project[] = [];
  loaderProject: boolean = false;
  currentProject: Project;
  currentFilterProject: string = '';

  environments: Environment[] = [];
  loaderEnvironment: boolean = false;
  currentEnvironment: Environment;
  currentFilterEnvironment: string = '';

  advanced = false;
  isExposed: boolean = true;
  sharedDomain: boolean = false;
  loading: boolean = false;
  formError = false;
  formSubmitted = false;

  steps = [
    { label: 'General', value: 1, disabled: true },
    { label: 'Exposition', value: 2, disabled: true },
    { label: 'Environments Variables', value: 3, disabled: true },
    { label: 'HPA/Resources', value: 4, disabled: true },
    { label: 'Volumes', value: 5, disabled: true },
    { label: 'Nodes Selectors', value: 6, disabled: true },
    { label: 'Tolerations', value: 7, disabled: true },
    { label: 'Probes', value: 8, disabled: true },
    { label: 'Args/Commands/Lifecycle', value: 9, disabled: true },
    { label: 'Container Security Context', value: 10, disabled: true },
    { label: 'Pod Security Context', value: 11, disabled: true },
  ];

  step = 1;

  loaderRegistry: boolean = false;
  registries: Registry[] = [];
  tempRegistries: Registry[] = [];
  currentRegistry: Registry;
  currentFilterRegistry: string = '';

  domains: Domain[] = [];
  tempDomains: Domain[] = [];
  loaderDomain: boolean = false;
  currentDomain: Domain;
  currentFilterDomain: string = '';
  randomElement: string = '';

  cpuUnits = cpuUnits;
  memoryUnits = memoryUnits;
  accessModes = ACCESS_MODES;
  storageUnits = [];
  resourceUnits = [];

  tolerationOperators = TOLERATION_OPERATORS;
  tolerationEffets = TOLERATION_EFFETS;

  randomStr = '';

  labelK8sRegex =
    '^([a-z0-9]([-a-z0-9]*[a-z0-9])?.)*[a-z0-9]([-a-z0-9]*[a-z0-9])?/[a-z0-9A-Z]([-a-z0-9A-Z_.]*[a-z0-9A-Z])?$|^[a-z0-9A-Z]([-a-z0-9A-Z_.]*[a-z0-9A-Z])?$';
  nameK8sRegex = '^[a-z0-9]([-a-z0-9]*[a-z0-9])?$';
  pathK8sRegex = '^/([a-zA-Z0-9._-]+(/[a-zA-Z0-9._-]+)*)?/?$';
  imageK8sRegex =
    '^(?:[a-z0-9]+(?:[._-][a-z0-9]+)*(?::[0-9]+)?/)?[a-z0-9]+(?:[._-][a-z0-9]+)*(?:/[a-z0-9]+(?:[._-][a-z0-9]+)*)*(?::[a-zA-Z0-9._-]+)?(?:@[a-fA-F0-9]{64})?$';
  // Independant properties
  imageTagFormControl: FormControl<any>;
  projectIdFormControl: FormControl;
  environmentIdFormControl: FormControl;
  registryIdFormControl: FormControl;
  domainIdFormControl: FormControl;
  subdomainFormControl: FormControl;

  requestCpuFormControl: FormControl;
  requestMemoryFormControl: FormControl;
  limitCpuFormControl: FormControl;
  limitMemoryFormControl: FormControl;

  socketFormControl: FormControl;
  publicRegistryEnabledFormControl: FormControl;
  // publicRegistryFormControl: FormControl;

  commandFormControl: FormControl;
  argsFormControl: FormControl;

  livenessProbeHeadersNameFormControl: FormControl;
  livenessProbeHeadersValueFormControl: FormControl;
  readinessProbeHeadersNameFormControl: FormControl;
  readinessProbeHeadersValueFormControl: FormControl;
  livenessExecCommandFormControl: FormControl;
  readinessExecCommandFormControl: FormControl;

  livenessTypeFormControl: FormControl;
  readinessTypeFormControl: FormControl;

  livenessAdvance = false;
  readinessAdvance = false;

  duplicate = false;

  probesTypes = [
    { label: 'HTTP Get', value: 'httpGet' },
    { label: 'Exec', value: 'exec' },
    { label: 'TCP Socket', value: 'tcpSocket' },
  ];

  capatibilities = CAPABILITIES;
  containerSecurityContextKeys = [
    {
      label: 'Capabilities',
      name: 'capabilities',
      selected: false,
    },
    {
      label: 'SE Linux Options',
      name: 'seLinuxOptions',
      selected: false,
    },
    {
      label: 'Seccomp Profile',
      name: 'seccompProfile',
      selected: false,
    },
    {
      label: 'Windows Options',
      name: 'windowsOptions',
      selected: false,
    },
    {
      label: 'Others',
      name: 'others',
      selected: false,
    },
  ];

  podSecurityContextKeys = [
    {
      label: 'SE Linux Options',
      name: 'podSeLinuxOptions',
      selected: false,
    },
    {
      label: 'Supplemental Groups',
      name: 'podSupplementalGroups',
      selected: false,
    },
    {
      label: 'Sysctls',
      name: 'podSysctls',
      selected: false,
    },
    {
      label: 'Seccomp Profile',
      name: 'podSeccompProfile',
      selected: false,
    },
    {
      label: 'Others',
      name: 'podOthers',
      selected: false,
    },
  ];

  publicRegistries = [
    {
      label: 'Docker',
      uri: 'index.docker.io',
    },
    {
      label: 'GitHub Container Registry (GHCR)',
      uri: 'ghcr.io',
    },
    {
      label: 'Google Container Registry (GCR)',
      uri: 'gcr.io',
    },
    {
      label: 'Amazon Elastic Container Registry (ECR)',
      uri: 'public.ecr.aws',
    },
    {
      label: 'Microsoft Azure Container Registry (ACR)',
      uri: 'mcr.microsoft.com',
    },
    {
      label: 'Quay.io',
      uri: 'quay.io',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private router: Router,
    private appService: AppService,
    private deploymentService: DeploymentService,
    private service: AsideExtenderService,
    private route: ActivatedRoute
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.randomStr = this.genStr(6);
    this.loading = true;

    let id = null;
    let projectId = null;
    let environmentId = null;

    this.route.params.subscribe((param: any) => {
      id = param.id;
    });
    this.route.queryParams.subscribe(async (param: any) => {
      projectId = param.projectId;
      environmentId = param.environmentId;
      this.duplicate = param.duplicate;
      if (this.duplicate) {
        id = param.id;
      }
    });

    this.loaderProject = true;
    await this.getProjects();

    if (id) {
      await this.getDeployment(projectId, environmentId, id, this.duplicate);
    }
    await this.getAllRegistries();

    this.selectProject();

    this.loaderProject = false;
    this.loading = false;
  }

  getDeployment(projectId, environmentId, id, duplicate = false) {
    return new Promise((resolve, reject) => {
      this.deploymentService
        .getDeployment(projectId, environmentId, id)
        .subscribe(
          (response: Deployment) => {
            this.deployment = response;
            if (duplicate) {
              const newName = `${this.deployment.metadata.name}-copy`;
              this.deployment.metadata.name = newName;
              this.deploymentForm.get('nameOverride').enable();
              this.onChangeName({ target: { value: newName } });
            } else {
              this.imageTagFormControl.disable();
              this.deploymentForm.get('nameOverride').disable();
            }
            this.setForm();
            resolve(response);
          },
          (error: any) => {
            this.service.show({
              title: 'Deploy',
              position: Position.TOP,
              type: Type.ERROR,
              message: 'Project or Environment nor found',
            });
            this.router.navigate(['/deploy']);
            reject(reject);
          }
        );
    });
  }

  createForm() {
    this.imageTagFormControl = this.fb.control('', [
      Validators.required,
      Validators.pattern(this.imageK8sRegex),
    ]);

    this.imageTagFormControl.valueChanges.subscribe((value) => {
      this.splitImageTag(value);
    });

    this.publicRegistryEnabledFormControl = this.fb.control(false);
    this.publicRegistryEnabledFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.registryIdFormControl.removeValidators([Validators.required]);
        this.registryIdFormControl.setValue('');
        this.currentRegistry = undefined;
      } else {
        this.registryIdFormControl.addValidators([Validators.required]);
        this.selectRegistry();
      }
      // this.imageTagFormControl.setValue('');
      this.registryIdFormControl.updateValueAndValidity();
    });
    this.projectIdFormControl = this.fb.control('', [Validators.required]);

    this.environmentIdFormControl = this.fb.control('', [Validators.required]);
    this.registryIdFormControl = this.fb.control('', [Validators.required]);
    this.domainIdFormControl = this.fb.control('', [Validators.required]);
    this.subdomainFormControl = this.fb.control('', [
      Validators.required,
      Validators.pattern('^[^.]*$'),
    ]);

    this.requestCpuFormControl = this.fb.control('m', [Validators.required]);
    this.requestMemoryFormControl = this.fb.control('Mi', [
      Validators.required,
    ]);
    this.limitCpuFormControl = this.fb.control('m', [Validators.required]);
    this.limitMemoryFormControl = this.fb.control('Mi', [Validators.required]);

    this.commandFormControl = this.fb.control('');
    this.argsFormControl = this.fb.control('');

    this.socketFormControl = this.fb.control(false);

    this.livenessProbeHeadersNameFormControl = this.fb.control('');
    this.livenessProbeHeadersValueFormControl = this.fb.control('');
    this.readinessProbeHeadersNameFormControl = this.fb.control('');
    this.readinessProbeHeadersValueFormControl = this.fb.control('');
    this.readinessExecCommandFormControl = this.fb.control('');
    this.livenessExecCommandFormControl = this.fb.control('');

    this.livenessTypeFormControl = this.fb.control('httpGet');
    this.readinessTypeFormControl = this.fb.control('httpGet');

    this.livenessTypeFormControl.valueChanges.subscribe((value) => {
      if (['httpGet'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: true } },
          'probes.livenessProbe'
        );
        this.clearField('probes.livenessProbe.exec');
        this.clearField('probes.livenessProbe.tcpSocket');
      }

      if (['exec'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: false } },
          'probes.livenessProbe'
        );
        this.clearField('probes.livenessProbe.httpGet');
        this.clearField('probes.livenessProbe.tcpSocket');
      }

      if (['tcpSocket'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: false } },
          'probes.livenessProbe'
        );
        this.clearField('probes.livenessProbe.httpGet');
        this.clearField('probes.livenessProbe.exec');
      }
    });

    this.readinessTypeFormControl.valueChanges.subscribe((value) => {
      if (['httpGet'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: true } },
          'probes.readinessProbe'
        );
        this.clearField('probes.readinessProbe.exec');
        this.clearField('probes.readinessProbe.tcpSocket');
      }

      if (['exec'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: false } },
          'probes.readinessProbe'
        );
        this.clearField('probes.readinessProbe.httpGet');
        this.clearField('probes.readinessProbe.tcpSocket');
      }

      if (['tcpSocket'].includes(value)) {
        this.onChangeEnableCheckbox(
          { target: { value: false } },
          'probes.readinessProbe'
        );
        this.clearField('probes.readinessProbe.httpGet');
        this.clearField('probes.readinessProbe.exec');
      }
    });

    this.deploymentForm = this.fb.group({
      nameOverride: this.fb.control('', [
        Validators.required,
        Validators.pattern('^[a-z0-9-]+(?<![-.])$'),
      ]),
      replicaCount: this.fb.control(1),
      port: this.fb.control('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      image: this.fb.group({
        repository: this.fb.control(''),
        tag: this.fb.control(''),
      }),
      ingress: this.fb.group({
        enabled: this.fb.control(false),
        labels: this.fb.group({}),
        host: this.fb.control('', [Validators.required]),
        paths: this.fb.array(
          [
            this.fb.group({
              path: this.fb.control('/', [
                Validators.required,
                Validators.pattern(this.pathK8sRegex),
              ]),
              pathType: this.fb.control('Prefix', Validators.required),
            }),
          ],
          this.noDuplicateKeys('path')
        ),
      }),

      volumes: this.fb.group({
        emptyDirs: this.fb.array([], this.noDuplicateKeys('name')),
        configMaps: this.fb.array([], this.noDuplicateKeys('name')),
        secrets: this.fb.array([], this.noDuplicateKeys('name')),
        pvcs: this.fb.array([], this.noDuplicateKeys('name')),
      }),
      env: this.fb.array([], this.noDuplicateKeys('key')),
      envFrom: this.fb.group({
        secrets: this.fb.array([], this.noDuplicateKeys('name')),
        configMaps: this.fb.array([], this.noDuplicateKeys('name')),
      }),
      lifecycle: this.fb.group({
        postStart: this.fb.group({
          command: this.fb.control('', [
            Validators.pattern('^([^;]+)(;[^;]+)*;?$'),
          ]),
        }),
      }),
      command: this.fb.array([]),
      args: this.fb.array([]),
      probes: this.fb.group({
        enabled: this.fb.control(false),
        livenessProbe: this.fb.group({
          enabled: this.fb.control(false),
          initialDelaySeconds: this.fb.control(5),
          periodSeconds: this.fb.control(10),
          httpGet: this.fb.group({
            path: this.fb.control('/healthz', [
              Validators.pattern(this.pathK8sRegex),
            ]),
            port: this.fb.control(null),
            httpHeaders: this.fb.array([], this.noDuplicateKeys('name')),
          }),
          tcpSocket: this.fb.group({
            port: this.fb.control(null),
          }),
          exec: this.fb.group({
            command: this.fb.array([]),
          }),
          timeoutSeconds: this.fb.control(null),
          successThreshold: this.fb.control(null),
          failureThreshold: this.fb.control(null),
        }),
        readinessProbe: this.fb.group({
          enabled: this.fb.control(false),
          initialDelaySeconds: this.fb.control(10),
          periodSeconds: this.fb.control(5),
          httpGet: this.fb.group({
            path: this.fb.control('/healthz', [
              Validators.pattern(this.pathK8sRegex),
            ]),
            port: this.fb.control(null),
            httpHeaders: this.fb.array([], this.noDuplicateKeys('name')),
          }),
          tcpSocket: this.fb.group({
            port: this.fb.control(null),
          }),
          exec: this.fb.group({
            command: this.fb.array([]),
          }),
          timeoutSeconds: this.fb.control(null),
          successThreshold: this.fb.control(null),
          failureThreshold: this.fb.control(null),
        }),
      }),
      resources: this.fb.group({
        limits: this.fb.group({
          cpu: this.fb.control(''),
          memory: this.fb.control(''),
        }),
        requests: this.fb.group({
          cpu: this.fb.control(''),
          memory: this.fb.control(''),
        }),
      }),
      autoscaling: this.fb.group({
        enabled: this.fb.control(false),
        minReplicas: this.fb.control(1),
        maxReplicas: this.fb.control(5),
        targetCPUUtilizationPercentage: this.fb.control(75),
        targetMemoryUtilizationPercentage: this.fb.control(75),
      }),
      nodeSelector: this.fb.array([]),
      tolerations: this.fb.array([]),

      podSecurityContext: this.fb.group({
        fsGroup: [''],
        runAsGroup: [''],
        runAsNonRoot: [false],
        runAsUser: [''],
        seLinuxOptions: this.fb.group({
          user: [''],
          role: [''],
          type: [''],
          level: [''],
        }),
        supplementalGroups: this.fb.array([]),
        sysctls: this.fb.array([]),
        seccompProfile: this.fb.group({
          type: [''],
          localhostProfile: [''],
        }),
      }),
      securityContext: this.fb.group({
        allowPrivilegeEscalation: [false],
        capabilities: this.fb.group({
          add: this.fb.array([]),
          drop: this.fb.array([]),
        }),
        privileged: [false],
        procMount: [''],
        readOnlyRootFilesystem: [false],
        runAsGroup: [''],
        runAsNonRoot: [false],
        runAsUser: [''],
        seLinuxOptions: this.fb.group({
          user: [''],
          role: [''],
          type: [''],
          level: [''],
        }),
        seccompProfile: this.fb.group({
          type: [''],
          localhostProfile: [''],
        }),
        windowsOptions: this.fb.group({
          gmsaCredentialSpecName: [''],
          runAsUserName: [''],
        }),
      }),
    });
  }

  clearField(type: string) {
    if (type == 'probes.livenessProbe.exec') {
      const commands = this.getFormArray(type + '.command').controls;

      for (let index = 0; index < commands.length; index++) {
        this.onRemoveFormGroup(type + '.command', index);
      }
    }

    if (type == 'probes.livenessProbe.httpGet') {
      const httpHeaders = this.getFormArray(type + '.httpHeaders').controls;
      for (let index = 0; index < httpHeaders.length; index++) {
        this.onRemoveFormGroup(type + '.httpHeaders', index);
      }

      this.deploymentForm.get(type + '.port').setValue(null);
      this.deploymentForm.get(type + '.path').setValue('');
    }

    if (type == 'probes.livenessProbe.tcpSocket') {
      this.deploymentForm.get(type + '.port').setValue(null);
    }
  }

  onAddElement(type: string) {
    switch (type) {
      case 'command':
        if (this.commandFormControl.value) {
          this.onAddFormGroup(type, this.commandFormControl.value);
          this.commandFormControl.setValue('');
        } else {
          this.commandFormControl.setValue(undefined);
        }
        break;
      case 'args':
        if (this.argsFormControl.value) {
          this.onAddFormGroup(type, this.argsFormControl.value);
          this.argsFormControl.setValue('');
        } else {
          this.argsFormControl.setValue(undefined);
        }
        break;
      case 'probes.livenessProbe.httpGet.httpHeaders':
        const livenessHeader = {
          name: this.livenessProbeHeadersNameFormControl.value,
          value: this.livenessProbeHeadersValueFormControl.value,
        };
        if (livenessHeader.name && livenessHeader.value) {
          this.onAddFormGroup(type, livenessHeader);
          this.livenessProbeHeadersNameFormControl.setValue('');
          this.livenessProbeHeadersValueFormControl.setValue('');
        } else {
          this.livenessProbeHeadersNameFormControl.setValue(undefined);
          this.livenessProbeHeadersValueFormControl.setValue(undefined);
        }
        break;
      case 'probes.readinessProbe.httpGet.httpHeaders':
        const readinessHeader = {
          name: this.readinessProbeHeadersNameFormControl.value,
          value: this.readinessProbeHeadersValueFormControl.value,
        };
        if (readinessHeader.name && readinessHeader.value) {
          this.onAddFormGroup(type, readinessHeader);
          this.readinessProbeHeadersNameFormControl.setValue('');
          this.readinessProbeHeadersValueFormControl.setValue('');
        } else {
          this.readinessProbeHeadersNameFormControl.setValue(undefined);
          this.readinessProbeHeadersValueFormControl.setValue(undefined);
        }
        break;
      case 'probes.livenessProbe.exec.command':
        if (this.livenessExecCommandFormControl.value) {
          this.onAddFormGroup(type, this.livenessExecCommandFormControl.value);
          this.livenessExecCommandFormControl.setValue('');
        } else {
          this.livenessExecCommandFormControl.setValue(undefined);
        }
        break;
      case 'probes.readinessProbe.exec.command':
        if (this.readinessExecCommandFormControl.value) {
          this.onAddFormGroup(type, this.readinessExecCommandFormControl.value);
          this.readinessExecCommandFormControl.setValue('');
        } else {
          this.readinessExecCommandFormControl.setValue(undefined);
        }
        break;
      default:
        break;
    }
  }

  onRemoveElement(type: string, index: number) {
    this.onRemoveFormGroup(type, index);
  }

  onBlurCommandFormControl() {
    if (this.commandFormControl.value) {
      this.onAddElement('command');
    }
  }

  onBlurArgsFormControl() {
    if (this.argsFormControl.value) {
      this.onAddElement('args');
    }
  }

  onBlurLivenessExecCommandFormControl() {
    if (this.livenessExecCommandFormControl.value) {
      this.onAddElement('probes.livenessProbe.exec.command');
    }
  }

  onBlurLivenessProbeHeadersFormControl() {
    if (
      this.livenessProbeHeadersNameFormControl.value &&
      this.livenessProbeHeadersValueFormControl.value
    ) {
      this.onAddElement('probes.livenessProbe.httpGet.httpHeaders');
    }
  }

  onBlurReadinessExecCommandFormControl() {
    if (this.readinessExecCommandFormControl.value) {
      this.onAddElement('probes.readinessProbe.exec.command');
    }
  }

  onBlurReadinessProbeHeadersFormControl() {
    if (
      this.readinessProbeHeadersNameFormControl.value &&
      this.readinessProbeHeadersValueFormControl.value
    ) {
      this.onAddElement('probes.readinessProbe.httpGet.httpHeaders');
    }
  }

  invalidCustomField() {
    if (
      this.imageTagFormControl.invalid ||
      this.projectIdFormControl.invalid ||
      this.environmentIdFormControl.invalid ||
      this.registryIdFormControl.invalid
    ) {
      return true;
    }
    return false;
  }

  invalidCustomFieldIngress() {
    if (this.deploymentForm.get('ingress.enabled').value) {
      if (
        this.domainIdFormControl.invalid ||
        this.subdomainFormControl.invalid
      ) {
        return true;
      }
    }
    return false;
  }

  splitImageTag(imageTag: string) {
    if (imageTag) {
      const imageTagSplit = imageTag.split(':');
      let tag = '';
      let repository = '';

      if (imageTagSplit.length == 1) {
        repository = imageTagSplit[0];
        tag = 'latest';
      }
      if (imageTagSplit.length == 2) {
        repository = imageTagSplit[0];
        tag = imageTagSplit[1];
      }

      this.deploymentForm.patchValue({
        image: {
          repository: repository,
          tag: tag,
        },
      });
    }
  }

  splitUnit(element: any, type) {
    const splitValue = (value: string, unit: string) => {
      if (value) {
        let unitFind = undefined;
        if (unit == 'cpu') {
          const mCore = this.cpuUnits[1];
          if (value.includes(mCore.value)) {
            unitFind = mCore;
          } else {
            unitFind = this.cpuUnits[0];
          }
        } else {
          unitFind = this.memoryUnits.find((unit) =>
            value.includes(unit.value)
          );
        }

        if (unitFind) {
          value = value.replace(unitFind.value, '');
        }

        return [value, unitFind?.value];
      }

      return [];
    };

    switch (type) {
      case 'resources':
        if (element?.requests) {
          const cpuValues = splitValue(element.requests.cpu, 'cpu');
          const memoryValues = splitValue(element.requests.memory, 'memory');
          element.requests.cpu = cpuValues[0];
          element.requests.memory = memoryValues[0];
          this.requestCpuFormControl.setValue(cpuValues[1]);
          this.requestMemoryFormControl.setValue(memoryValues[1]);
        }
        if (element?.limits) {
          const cpuValues = splitValue(element.limits.cpu, 'cpu');
          const memoryValues = splitValue(element.limits.memory, 'memory');
          element.limits.cpu = cpuValues[0];
          element.limits.memory = memoryValues[0];
          this.limitCpuFormControl.setValue(cpuValues[1]);
          this.limitMemoryFormControl.setValue(memoryValues[1]);
        }
        break;
      case 'pvcs.resources.requests':
        if (element) {
          if (element.length > 0) {
            for (let index = 0; index < element.length; index++) {
              if (element[index]?.existingPvc != true) {
                const memoryValues = splitValue(
                  element[index].resources.requests.storage,
                  'memory'
                );

                element[index].resources.requests.storage = memoryValues[0];
                this.onAddFormGroup('volumes.pvcs', element[index]);

                setTimeout(() => {
                  const el: any = <HTMLInputElement>(
                    document.getElementById('requestsStorage' + index)
                  );
                  if (el) {
                    el.value = memoryValues[1];
                  }
                }, 300);
              } else {
                this.onAddFormGroup('volumes.pvcs', element[index]);
              }
            }
          }
        }
        break;
      default:
        break;
    }

    return element;
  }

  setForm() {
    if (this.deployment?.registryId) {
      this.registryIdFormControl.setValue(this.deployment?.registryId);
      this.publicRegistryEnabledFormControl.setValue(false);
    } else {
      this.publicRegistryEnabledFormControl.setValue(true);
      this.imageTagFormControl.setValue(
        `${this.deployment?.spec?.image?.repository}:${this.deployment?.spec?.image?.tag}`
      );
    }

    if (this.deployment.spec?.probes?.livenessProbe?.enabled) {
      if (this.deployment.spec?.probes?.livenessProbe?.exec?.command) {
        this.livenessTypeFormControl.setValue('exec');
      } else if (
        this.deployment?.spec?.probes?.livenessProbe?.tcpSocket?.port
      ) {
        this.livenessTypeFormControl.setValue('tcpSocket');
      } else {
        this.livenessTypeFormControl.setValue('httpGet');
      }
    }

    if (this.deployment.spec?.probes?.readinessProbe?.enabled) {
      if (this.deployment.spec?.probes?.readinessProbe?.exec?.command) {
        this.livenessTypeFormControl.setValue('exec');
      } else if (
        this.deployment?.spec?.probes?.readinessProbe?.tcpSocket?.port
      ) {
        this.livenessTypeFormControl.setValue('tcpSocket');
      } else {
        this.livenessTypeFormControl.setValue('httpGet');
      }
    }

    this.onRemoveFormGroup('ingress.paths', 0);

    this.splitUnit(this.deployment?.spec?.resources, 'resources');

    if (this.deployment?.spec?.ingress?.labels) {
      let label: any = this.deployment?.spec?.ingress?.labels;
      let defaultKey = 'door.cloudoor.com/enable-websockets';
      let values = Object.values(label);
      let keys = Object.keys(label);

      for (let index = 0; index < values.length; index++) {
        let value: any = values[index];
        const key = keys[index];
        if (defaultKey == key) {
          if (value == 'true') {
            this.socketFormControl.setValue(true);
          } else {
            this.socketFormControl.setValue(false);
          }
        }
      }
    }

    const currentEnvars = this.deployment?.spec?.env;
    if (currentEnvars) {
      const keys = Object.keys(currentEnvars);
      const values = Object.values(currentEnvars);

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const value = values[index];
        this.onAddFormGroup('env', { key: key, value: value });
      }
    }

    let ingressPaths = this.deployment?.spec?.ingress?.paths;

    if (ingressPaths) {
      if (ingressPaths.length == 0) {
        ingressPaths = [{ path: '/', pathType: 'Prefix' }];
      }

      for (let index = 0; index < ingressPaths.length; index++) {
        const value = ingressPaths[index];
        this.onAddFormGroup('ingress.paths', value);
      }
    }

    const emptyDirs = this.deployment?.spec?.volumes?.emptyDirs;
    if (emptyDirs) {
      for (let index = 0; index < emptyDirs.length; index++) {
        const value = emptyDirs[index];
        this.onAddFormGroup('volumes.emptyDirs', value);
      }
    }

    const configMaps = this.deployment?.spec?.volumes?.configMaps;
    if (configMaps) {
      for (let index = 0; index < configMaps.length; index++) {
        const value = configMaps[index];
        this.onAddFormGroup('volumes.configMaps', value);
      }
    }

    const secrets = this.deployment?.spec?.volumes?.secrets;
    if (secrets) {
      for (let index = 0; index < secrets.length; index++) {
        const value = secrets[index];
        this.onAddFormGroup('volumes.secrets', value);
      }
    }

    this.splitUnit(
      this.deployment?.spec?.volumes?.pvcs,
      'pvcs.resources.requests'
    );

    const envFromSecrets = this.deployment?.spec?.envFrom?.secrets;
    if (envFromSecrets) {
      for (let index = 0; index < envFromSecrets.length; index++) {
        const value = envFromSecrets[index];
        this.onAddFormGroup('envFrom.secrets', value);
      }
    }

    const envFromconfigMaps = this.deployment?.spec?.envFrom?.configMaps;
    if (envFromconfigMaps) {
      for (let index = 0; index < envFromconfigMaps.length; index++) {
        const value = envFromconfigMaps[index];
        this.onAddFormGroup('envFrom.configMaps', value);
      }
    }

    const autoscalingEnabled = {
      target: { checked: this.deployment?.spec?.autoscaling?.enabled },
    };
    this.onChangeEnableCheckbox(autoscalingEnabled, 'autoscaling');

    const probesLivenessEnabled = {
      target: {
        checked: this.deployment?.spec?.probes?.livenessProbe?.enabled,
      },
    };
    const probesReadinessEnabled = {
      target: {
        checked: this.deployment?.spec?.probes?.readinessProbe?.enabled,
      },
    };
    this.onChangeEnableCheckbox(probesLivenessEnabled, 'probes.livenessProbe');
    this.onChangeEnableCheckbox(
      probesReadinessEnabled,
      'probes.readinessProbe'
    );

    const tolerations = this.deployment?.spec?.tolerations;
    if (tolerations) {
      for (let index = 0; index < tolerations.length; index++) {
        const value = tolerations[index];
        this.onAddFormGroup('tolerations', value);
      }
    }

    const capabilities = this.deployment?.spec?.securityContext?.capabilities;
    if (capabilities) {
      if (capabilities.add) {
        for (let index = 0; index < capabilities.add.length; index++) {
          const element = capabilities.add[index];
          this.onAddFormGroup('securityContext.capabilities.add', element);
        }
      }
      if (capabilities.drop) {
        for (let index = 0; index < capabilities.drop.length; index++) {
          const element = capabilities.drop[index];
          this.onAddFormGroup('securityContext.capabilities.drop', element);
        }
      }
    }

    const supplementalGroups =
      this.deployment?.spec?.podSecurityContext?.supplementalGroups;
    if (supplementalGroups) {
      for (let index = 0; index < supplementalGroups.length; index++) {
        const element = supplementalGroups[index];
        this.onAddFormGroup('podSecurityContext.supplementalGroups', element);
      }
    }

    const sysctls = this.deployment?.spec?.podSecurityContext?.sysctls;
    if (sysctls) {
      for (let index = 0; index < sysctls.length; index++) {
        const element = sysctls[index];
        this.onAddFormGroup('podSecurityContext.sysctls', element);
      }
    }

    const command = this.deployment?.spec?.command;
    if (command) {
      for (let index = 0; index < command.length; index++) {
        const element = command[index];
        this.onAddFormGroup('command', element);
      }
    }

    const args = this.deployment?.spec?.args;
    if (args) {
      for (let index = 0; index < args.length; index++) {
        const element = args[index];
        this.onAddFormGroup('args', element);
      }
    }

    const livenessHttpHeaders =
      this.deployment?.spec?.probes?.livenessProbe?.httpGet?.httpHeaders;
    if (livenessHttpHeaders) {
      for (let index = 0; index < livenessHttpHeaders.length; index++) {
        const element = livenessHttpHeaders[index];
        this.onAddFormGroup(
          'probes.livenessProbe.httpGet.httpHeaders',
          element
        );
      }
    }

    const readinessHttpHeaders =
      this.deployment?.spec?.probes?.readinessProbe?.httpGet?.httpHeaders;
    if (readinessHttpHeaders) {
      for (let index = 0; index < readinessHttpHeaders.length; index++) {
        const element = readinessHttpHeaders[index];
        this.onAddFormGroup(
          'probes.readinessProbe.httpGet.httpHeaders',
          element
        );
      }
    }

    const livenessExecCommand =
      this.deployment?.spec?.probes?.livenessProbe?.exec?.command;
    if (livenessExecCommand) {
      for (let index = 0; index < livenessExecCommand.length; index++) {
        const element = livenessExecCommand[index];
        this.onAddFormGroup('probes.livenessProbe.exec.command', element);
      }
    }

    const readinessExecCommand =
      this.deployment?.spec?.probes?.readinessProbe?.exec?.command;
    if (readinessExecCommand) {
      for (let index = 0; index < readinessExecCommand.length; index++) {
        const element = readinessExecCommand[index];
        this.onAddFormGroup('probes.readinessProbe.exec.command', element);
      }
    }

    this.deploymentForm.patchValue({
      nameOverride: this.deployment?.metadata?.name,
      replicaCount: 1,
      port: this.deployment?.spec?.port,
      ingress: this.deployment?.spec?.ingress,
      lifecycle: this.deployment?.spec?.lifecycle,
      probes: this.deployment?.spec?.probes,
      resources: this.deployment?.spec?.resources,
      autoscaling: this.deployment?.spec?.autoscaling,
      securityContext: this.deployment?.spec?.securityContext,
      podSecurityContext: this.deployment?.spec?.podSecurityContext,
    });
  }

  invalidStep(step: number) {
    if (step == 1) {
      if (
        this.invalidCustomField() ||
        this.deploymentForm.get('nameOverride').invalid ||
        this.deploymentForm.get('port').invalid
      ) {
        return true;
      }
    }

    if (step == 2) {
      if (
        this.invalidCustomFieldIngress() ||
        this.deploymentForm.get('ingress.paths').invalid
      ) {
        return true;
      }
    }

    if (step == 3) {
      if (
        this.deploymentForm.get('env').invalid ||
        this.deploymentForm.get('envFrom').invalid
      ) {
        return true;
      }
    }
    if (step == 4) {
      if (this.deploymentForm.get('autoscaling.enabled').value) {
        if (this.deploymentForm.get('autoscaling').invalid) {
          return true;
        }
      }
      if (this.deploymentForm.get('resources').invalid) {
        return true;
      }
    }
    if (step == 5) {
      if (this.deploymentForm.get('volumes').invalid) {
        return true;
      }
    }
    if (step == 6) {
      if (this.deploymentForm.get('nodeSelector').invalid) {
        return true;
      }
    }
    if (step == 7) {
      if (this.deploymentForm.get('tolerations').invalid) {
        return true;
      }
    }
    if (step == 8) {
      if (this.deploymentForm.get('probes').invalid) {
        return true;
      }
    }
    return false;
  }

  disableStep(step: number, index: number) {
    this.steps[index].disabled = false;

    for (let i = 0; i < this.steps.length; i++) {
      const element = this.steps[i];
      if (this.invalidStep(step) && element.value > step) {
        this.steps[i].disabled = true;
      }
    }

    return this.steps[index].disabled;
  }

  // Validator
  noDuplicateKeys(key: string): ValidatorFn {
    const keyOf = key;
    return (formArray: AbstractControl): { [key: string]: boolean } | null => {
      if (!(formArray instanceof FormArray)) {
        return null;
      }

      const keyValues = formArray.controls.map((control) =>
        keyOf
          ? (control as FormGroup).get(keyOf)?.value
          : (control as FormControl)?.value
      );

      const keyOccurrences = keyValues.reduce((acc, keyValue) => {
        acc[keyValue] = (acc[keyValue] || 0) + 1;
        return acc;
      }, {});

      formArray.controls.forEach((control, index) => {
        const keyControl = keyOf
          ? (control as FormGroup).get(keyOf)
          : (control as FormControl);
        if (keyControl) {
          if (keyOccurrences[keyControl.value] > 1) {
            keyControl.setErrors({ duplicate: true });
          } else {
            if (keyControl.hasError('duplicate')) {
              delete keyControl.errors['duplicate'];
              if (!Object.keys(keyControl.errors).length) {
                keyControl.setErrors(null);
              }
            }
          }
        }
      });

      return keyValues.some((key) =>
        keyOf ? keyOccurrences[key] > 1 : keyOccurrences > 1
      )
        ? { duplicateKeys: true }
        : null;
    };
  }

  genStr(length: number): string {
    return generateRandomString(length);
  }

  getFormArray(type: string): FormArray {
    return this.deploymentForm.get(type) as FormArray;
  }

  // REGISTRY FUNCTIONS
  getAllRegistries() {
    return new Promise((resolve, reject) => {
      this.loaderRegistry = true;
      this.appService.getRegistries().subscribe(
        (response: Registry[]) => {
          this.registries = response;
          this.tempRegistries = response;
          this.loaderRegistry = false;
          this.selectRegistry();
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  onFilterRegistry(value: string) {
    this.currentFilterRegistry = '';
    if (value) {
      this.currentFilterRegistry = value;
      this.registries = this.tempRegistries.filter((r) =>
        r.name.includes(value)
      );
    } else {
      this.registries = this.tempRegistries;
    }
  }

  onChangeRegistry(value: any) {
    this.currentRegistry = this.registries.find((p) => p.id == value);
    if (this.deployment?.id) {
      let repository = this.deployment?.spec?.image?.repository;
      let tag = this.deployment?.spec?.image?.tag;
      let imageStr = '';

      if (this.deployment?.spec?.image.tag) {
        imageStr = `${repository}:${tag}`;
      } else {
        imageStr = `${repository}:latest`;
      }

      this.imageTagFormControl.setValue(imageStr);
    }
  }

  selectRegistry() {
    let registry = undefined;
    if (this.deployment?.id) {
      registry = this.registries.find(
        (r) => r.id == this.deployment?.registryId
      );
    } else {
      if (!this.publicRegistryEnabledFormControl.value) {
        if (this.registries.length == 1) {
          registry = this.registries[0];
        }
      }
    }

    this.registryIdFormControl.setValue(registry?.id);
    this.currentRegistry = registry;
  }

  // ENVIRONMENT FUNCTIONS
  async onChangeEnvironment(value: any) {
    const environments = this.currentProject.environments;
    if (environments.length > 0) {
      this.currentEnvironment = environments.find((e) => e.id == value);
      if (this.currentEnvironment) {
        this.loaderDomain = true;
        await this.getDomainsByEnv(this.currentEnvironment.id);
        this.selectDomain();
        this.loaderDomain = false;
      }
    }
  }

  onFilterEnvironment(value: string) {
    const environments = this.currentProject.environments;
    this.currentFilterEnvironment = '';
    if (value) {
      this.currentFilterEnvironment = value;
      this.environments = environments.filter((e) => e.name.includes(value));
    } else {
      this.environments = environments;
    }
  }

  selectEnvironment() {
    let environments = [];
    let env = undefined;

    if (this.currentProject?.id) {
      environments = this.currentProject.environments;
    }

    if (this.deployment?.id) {
      env = environments.find((e) => e.id === this.deployment?.environmentId);
    } else {
      if (environments.length == 1) {
        env = environments[0];
      }
    }

    this.currentEnvironment = env;
    this.environmentIdFormControl.setValue(env?.id);
  }

  getEnvironmentDeployment() {
    if (this.deployment?.environmentId) {
      for (let index = 0; index < this.projects.length; index++) {
        const project = this.projects[index];
        this.currentEnvironment = project.environments.find(
          (e) => e.id == this.deployment?.environmentId
        );

        if (this.currentEnvironment) {
          this.environments = project.environments;

          this.projectIdFormControl.setValue(project.id);
          this.environmentIdFormControl.setValue(this.currentEnvironment.id);
          break;
        }
      }
    }
  }

  loadEnvironmentVariableFromFile(event: any) {
    event.forEach((m: any, index: any) => {
      let key = m[0];
      if (m[0][0] == `"` || m[0][0] == `'`) {
        key = m[0].slice(1);
        key = key.slice(0, [key.length - 1]);
      }
      let value = m[1];
      if (m[1][0] == `"` || m[1][0] == `'`) {
        value = m[1].slice(1);
        value = value.slice(0, [value.length - 1]);
      }

      this.onAddFormGroup('env', { key: key, value: value });
    });
  }

  // DOMAIN FUNCTIONS
  async getDomainsByEnv(environmentId: string) {
    await this.appService
      .getDomainsByEnv(environmentId)
      .toPromise()
      .then((response: Domain[]) => {
        this.domains = response;
        this.tempDomains = response;
      });
  }

  onChangeDomain(value: any) {
    this.currentDomain = this.domains.find((p) => p.id == value);
  }

  selectDomain() {
    const genStr = this.randomStr;

    let domain = undefined;
    let host = '';

    if (this.currentDomain?.id) {
      domain = this.tempDomains.find((d) => d.id == this.deployment?.domainId);
    } else {
      if (this.tempDomains.length == 1) {
        domain = this.tempDomains[0];
      }
    }

    if (domain) {
      this.currentDomain = domain;
      this.domainIdFormControl.setValue(domain?.id);

      if (this.deployment?.spec?.ingress?.host && !this.duplicate) {
        const subdomain = this.deployment?.spec?.ingress?.host.replace(
          `.${domain?.host}`,
          ''
        );
        this.subdomainFormControl.setValue(subdomain);
      }

      if (this.subdomainFormControl.value && !this.duplicate) {
        host = `${this.subdomainFormControl.value}.${domain.host}`;
      } else {
        const name = this.deploymentForm.get('nameOverride').value;

        const subdomain = name ? `${name}-${genStr}` : genStr;

        this.subdomainFormControl.setValue(subdomain);

        host = `${subdomain}.${domain.host}`;
      }

      this.deploymentForm.patchValue({
        ingress: {
          host: host,
        },
      });

      if (domain?.shared == true) {
        this.sharedDomain = true;
        this.subdomainFormControl.disable();
      } else {
        this.subdomainFormControl.enable();
        this.domainIdFormControl.enable();
        this.sharedDomain = false;
      }
    }
  }

  onFilterDomain(value) {}

  // PROJECT FUNCTIONS
  onChangeProject(value: any) {
    this.currentProject = this.projects.find((p) => p.id == value);
    if (this.currentProject?.environments) {
      this.environments = this.currentProject?.environments;
    } else {
      this.environments = [];
    }

    this.selectEnvironment();
  }

  async onFilterProject(value: string) {
    this.currentFilterProject = value;
    await this.getProjects(value);
  }

  getProjects(search: string = '') {
    return new Promise((resolve, reject) => {
      this.loaderEnvironment = true;
      this.projectIdFormControl.disable();
      this.environmentIdFormControl.disable();
      this.projectService.getProjectsByOrganisation(search).subscribe(
        (response) => {
          this.projects = response.records;
          this.projectIdFormControl.enable();
          this.loaderEnvironment = false;
          this.environmentIdFormControl.enable();
          resolve(response);
        },
        (error) => {
          this.service.show({
            title: 'Deploy',
            position: Position.TOP,
            type: Type.ERROR,
            message: 'Project or Environment nor found',
          });
          this.router.navigate(['/deploy']);
          reject(error);
        }
      );
    });
  }

  selectProject() {
    let project = undefined;

    if (this.deployment?.id) {
      project = this.projects.find((p) => p.id === this.deployment?.projectId);
    } else {
      if (this.projects.length == 1) {
        project = this.projects[0];
      }
    }
    this.projectIdFormControl.setValue(project?.id);
    this.currentProject = project;

    this.selectEnvironment();
  }

  onChangeIsExposed(event: any) {
    this.isExposed = event.target.checked;
  }

  onChangeName(event: any) {
    const genStr = this.randomStr;

    const value = event.target.value;
    const subdomain = value ? `${value}-${genStr}` : `${genStr}`;

    this.subdomainFormControl.enable();
    this.subdomainFormControl.setValue(subdomain);
    this.subdomainFormControl.disable();

    this.deploymentForm.patchValue({
      ingress: {
        host: `${subdomain}.${this.currentDomain?.host}`,
      },
    });
  }

  onChangeEnableCheckbox(event: any, type?: string) {
    const checked = event.target.checked;

    const validators1 = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(1),
    ];

    const validators2 = [Validators.pattern('^[0-9]*$'), Validators.min(1)];
    const validators3 = [
      Validators.required,
      Validators.pattern(this.pathK8sRegex),
    ];

    switch (type) {
      case 'autoscaling':
        const autoscalingControls = [
          this.deploymentForm.get('autoscaling.minReplicas'),
          this.deploymentForm.get('autoscaling.maxReplicas'),
          this.deploymentForm.get('autoscaling.targetCPUUtilizationPercentage'),
          this.deploymentForm.get(
            'autoscaling.targetMemoryUtilizationPercentage'
          ),
        ];

        const resourceControls = [
          this.deploymentForm.get('resources.requests.cpu'),
          this.deploymentForm.get('resources.requests.memory'),
          this.deploymentForm.get('resources.limits.cpu'),
          this.deploymentForm.get('resources.limits.memory'),
        ];

        if (checked) {
          // autoscalingControls
          for (let index = 0; index < autoscalingControls.length; index++) {
            const control: any = autoscalingControls[index];
            if (index == 3) {
              this.addValidators(control, validators2);
              continue;
            }
            this.addValidators(control, validators1);
          }

          // resourceControls
          for (let index = 0; index < resourceControls.length; index++) {
            const control: any = resourceControls[index];
            this.addValidators(control, validators1);
          }
        } else {
          // autoscalingControls
          for (let index = 0; index < autoscalingControls.length; index++) {
            const control: any = autoscalingControls[index];
            if (index == 3) {
              this.removeValidators(control, validators2);
              continue;
            }
            this.removeValidators(control, validators1);
          }

          // resourceControls
          for (let index = 0; index < resourceControls.length; index++) {
            const control: any = resourceControls[index];
            this.removeValidators(control, validators1);
          }
        }
        break;
      case 'probes.livenessProbe':
        const probeslivenessProbeControls = [
          this.deploymentForm.get('probes.livenessProbe.initialDelaySeconds'),
          this.deploymentForm.get('probes.livenessProbe.periodSeconds'),
          this.deploymentForm.get('probes.livenessProbe.httpGet.path'),
          this.deploymentForm.get('probes.livenessProbe.httpGet.port'),
        ];

        if (checked) {
          // probesControls
          for (
            let index = 0;
            index < probeslivenessProbeControls.length;
            index++
          ) {
            const control: any = probeslivenessProbeControls[index];
            if (index == 2) {
              this.addValidators(control, validators3);
              continue;
            }
            this.addValidators(control, validators1);
          }
        } else {
          // probesControls
          for (
            let index = 0;
            index < probeslivenessProbeControls.length;
            index++
          ) {
            const control: any = probeslivenessProbeControls[index];
            if (index == 2) {
              this.removeValidators(control, validators3);
              continue;
            }
            this.removeValidators(control, validators1);
          }
        }
        break;
      case 'probes.readinessProbe':
        const probesreadinessProbeControls = [
          this.deploymentForm.get('probes.readinessProbe.initialDelaySeconds'),
          this.deploymentForm.get('probes.readinessProbe.periodSeconds'),
          this.deploymentForm.get('probes.readinessProbe.httpGet.path'),
          this.deploymentForm.get('probes.readinessProbe.httpGet.port'),
        ];

        if (checked) {
          // probesControls
          for (
            let index = 0;
            index < probesreadinessProbeControls.length;
            index++
          ) {
            const control: any = probesreadinessProbeControls[index];
            if (index == 2) {
              this.addValidators(control, validators3);
              continue;
            }
            this.addValidators(control, validators1);
          }
        } else {
          // probesControls
          for (
            let index = 0;
            index < probesreadinessProbeControls.length;
            index++
          ) {
            const control: any = probesreadinessProbeControls[index];
            if (index == 2) {
              this.removeValidators(control, validators3);
              continue;
            }
            this.removeValidators(control, validators1);
          }
        }
        break;
      default:
        break;
    }
  }

  onChangeExistingPvc(event, index) {
    const checked = event.target.checked;

    const controls = [
      this.deploymentForm.get(`volumes.pvcs.${index}.accessModes.0`),
      this.deploymentForm.get(
        `volumes.pvcs.${index}.resources.requests.storage`
      ),
    ];
    if (checked) {
      this.removeValidators(controls[0], [Validators.required]);
      this.removeValidators(controls[1], [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
    } else {
      this.addValidators(controls[0], [Validators.required]);
      this.addValidators(controls[1], [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
    }
  }

  addValidators(control: AbstractControl, validators: any) {
    control.addValidators(validators);
    control.updateValueAndValidity();
  }

  removeValidators(control: AbstractControl, validators: any) {
    control.removeValidators(validators);
    control.updateValueAndValidity();
  }

  // On add dynamic
  onAddFormGroup(type: string, data?: any) {
    let formArray = this.getFormArray(type);
    formArray.push(this.createFormGroup(type, data));
  }

  onRemoveFormGroup(type: string, index: number) {
    let formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }

  private createFormGroup(type: string, data?: any): AbstractControl {
    let abstractControl: AbstractControl;

    switch (type) {
      case 'ingress.paths':
        abstractControl = this.fb.group({
          path: this.fb.control(data?.path, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          pathType: this.fb.control('Prefix', Validators.required),
        });
        break;
      case 'env':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*$'),
          ]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'volumes.emptyDirs':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'volumes.configMaps':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          configMapRefName: this.fb.control(data?.configMapRefName, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'volumes.secrets':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          secretRefName: this.fb.control(data?.secretRefName, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'volumes.pvcs':
        abstractControl = this.fb.group({
          existingPvc: this.fb.control(data?.existingPvc ? true : false),
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          enabled: this.fb.control(true),
          storageClass: this.fb.control(data?.storageClass),
          accessModes: this.fb.array([
            this.fb.control(
              data?.existingPvc != true ? data?.accessModes[0] : '',
              data?.existingPvc != true ? [Validators.required] : []
            ),
          ]),
          resources: this.fb.group({
            requests: this.fb.group({
              storage: this.fb.control(
                data?.existingPvc != true
                  ? data?.resources?.requests?.storage
                  : '',
                data?.existingPvc != true
                  ? [Validators.required, Validators.pattern('^[0-9]*$')]
                  : []
              ),
            }),
          }),
        });
        break;
      case 'envFrom.secrets':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
        });
        break;
      case 'envFrom.configMaps':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
        });
        break;
      case 'nodeSelector':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern(this.labelK8sRegex),
          ]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'tolerations':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern(this.labelK8sRegex),
          ]),
          operator: this.fb.control(data?.operator, [Validators.required]),
          value: this.fb.control(data?.value, [Validators.required]),
          effect: this.fb.control(data?.effect, [Validators.required]),
        });
        break;
      case 'podSecurityContext.sysctls':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.key, [Validators.required]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'podSecurityContext.supplementalGroups':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'securityContext.capabilities.add':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'securityContext.capabilities.drop':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'command':
        abstractControl = this.fb.control(data);
        break;
      case 'args':
        abstractControl = this.fb.control(data);
        break;
      case 'probes.livenessProbe.httpGet.httpHeaders':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name),
          value: this.fb.control(data?.value),
        });
        break;
      case 'probes.readinessProbe.httpGet.httpHeaders':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name),
          value: this.fb.control(data?.value),
        });
        break;
      case 'probes.livenessProbe.exec.command':
        abstractControl = this.fb.control(data);
        break;
      case 'probes.readinessProbe.exec.command':
        abstractControl = this.fb.control(data);
        break;
      default:
        break;
    }

    return abstractControl;
  }

  // On Change
  onChangeStorage(event: any, index: any) {
    this.storageUnits[index] = event.target.value;
  }

  onChangeResource(event: any, index: any) {
    this.resourceUnits[index] = event.target.value;
  }

  cleanSpecs(specs: any): any {
    const isEmpty = (value: any) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (value && typeof value === 'object') {
        return Object.keys(value).length === 0;
      } else if (typeof value === 'string') {
        return value.trim() === '';
      }
      return value === null || value === undefined;
    };

    const cleanValue = (value: any): any => {
      if (Array.isArray(value)) {
        return value
          .map((item) => cleanValue(item))
          .filter((item) => !isEmpty(item));
      } else if (value && typeof value === 'object') {
        return this.cleanSpecs(value);
      }
      return value;
    };

    const newSpecs = {};
    for (const key in specs) {
      if (specs.hasOwnProperty(key)) {
        const value = cleanValue(specs[key]);
        if (!isEmpty(value)) {
          newSpecs[key] = value;
        }
      }
    }

    return newSpecs;
  }

  formatData(elements: any) {
    let newData: any = {};
    if (elements.length > 0) {
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        newData[element.key] = element.value;
      }
    }
    return newData;
  }

  setUnit(element: any, type: string) {
    switch (type) {
      case 'resources':
        if (element) {
          if (element.requests) {
            element.requests.cpu += this.requestCpuFormControl.value;
            element.requests.memory += this.requestMemoryFormControl.value;
          }
          if (element.limits) {
            element.limits.cpu += this.limitCpuFormControl.value;
            element.limits.memory += this.limitMemoryFormControl.value;
          }
        }
        break;
      case 'pvcs.resources.storage':
        if (element) {
          this.storageUnits;
          if (element.length > 0) {
            for (let index = 0; index < element.length; index++) {
              if (!element[index]?.existingPvc) {
                const el: any = <HTMLInputElement>(
                  document.getElementById('requestsStorage' + index)
                );
                if (el) {
                  const value = el.value;
                  element[index].resources.requests.storage += value;
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }

    return element;
  }

  parseValue(value: any, type: string): any {
    switch (type) {
      case 'string':
        return String(value);
      case 'number':
        return Number(value);
      case 'boolean':
        return Boolean(value);
      default:
        return value;
    }
  }

  deepParseObjectValues(obj: any, type: string, attributes: string[]): any {
    const parsedObject: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          parsedObject[key] = this.deepParseObjectValues(
            value,
            type,
            attributes
          );
        } else if (attributes.includes(key)) {
          parsedObject[key] = this.parseValue(value, type);
        } else {
          parsedObject[key] = value;
        }
      }
    }

    return parsedObject;
  }

  transformBooleansToEmptyString(obj: any): any {
    const transformedObject: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          transformedObject[key] = this.transformBooleansToEmptyString(value);
        } else if (typeof value === 'boolean' && !value) {
          transformedObject[key] = '';
        } else {
          transformedObject[key] = value;
        }
      }
    }

    return transformedObject;
  }

  onSubmit(): void {
    let submitDeployment = {} as Deployment;

    this.onBlurCommandFormControl();
    this.onBlurArgsFormControl();
    this.onBlurLivenessExecCommandFormControl();
    this.onBlurLivenessProbeHeadersFormControl();
    this.onBlurReadinessExecCommandFormControl();
    this.onBlurReadinessProbeHeadersFormControl();

    this.formError = true;
    if (
      this.deploymentForm.invalid ||
      this.invalidCustomField() ||
      this.invalidCustomFieldIngress()
    ) {
      return;
    }

    this.deploymentForm.get('nameOverride').enable();
    const specs: DeploymentSpecs = this.deploymentForm.value;

    specs.env = this.formatData(this.deploymentForm.value.env) as Env;
    specs.nodeSelector = this.formatData(
      this.deploymentForm.value.nodeSelector
    );

    const annotations = {
      'httpproxy.door.cloudoor.com/include-path': 'true',
    };
    specs.ingress.annotations = annotations;

    const labels = {};

    if (this.currentDomain && specs.ingress.enabled) {
      specs.ingress.secretName = this.currentDomain?.certificateSecretName;
      labels['door.cloudoor.com/certificat-wildcard-name'] =
        this.currentDomain?.certificateSecretName;
      labels['door.cloudoor.com/certificat-wildcard-namespace'] =
        this.currentDomain?.certificateSecretNamespace;
    }

    if (this.socketFormControl.value) {
      labels['door.cloudoor.com/certificat-wildcard-namespace'] =
        this.socketFormControl.value.toString();
    }

    specs.ingress.labels = labels;

    if (specs?.securityContext) {
      specs.securityContext = this.transformBooleansToEmptyString(
        specs.securityContext
      );
    }

    if (specs?.podSecurityContext) {
      specs.podSecurityContext = this.transformBooleansToEmptyString(
        specs.podSecurityContext
      );
    }
    submitDeployment.metadata = {} as Metadata;
    submitDeployment.spec = this.cleanSpecs(specs);

    if (submitDeployment?.spec?.probes) {
      submitDeployment.spec.probes = this.deepParseObjectValues(
        submitDeployment.spec.probes,
        'number',
        ['port', 'timeoutSeconds', 'successThreshold', 'failureThreshold']
      );
    }

    this.setUnit(submitDeployment.spec?.resources, 'resources');
    this.setUnit(
      submitDeployment.spec?.volumes?.pvcs,
      'pvcs.resources.storage'
    );

    if (!submitDeployment?.spec?.ingress?.enabled) {
      submitDeployment.spec.ingress.host = '';
      submitDeployment.spec.ingress.paths = [];
      specs.ingress.secretName = '';
      specs.ingress.annotations = {};
    }

    if (
      this.currentRegistry?.id &&
      !this.publicRegistryEnabledFormControl.value
    ) {
      submitDeployment.registryId = this.currentRegistry?.id;
    }

    submitDeployment.environmentId = this.currentEnvironment?.id;
    submitDeployment.projectId = this.currentProject?.id;
    submitDeployment.organizationId = this.currentProject?.organizationId;
    submitDeployment.domainId = this.currentDomain?.id;
    submitDeployment.metadata.name = specs.nameOverride;
    submitDeployment.metadata.namespace = this.currentEnvironment?.namespace;

    if (this.duplicate) {
      this.deployment = undefined;
    }

    if (this.deployment?.id) {
      this.formSubmitted = true;
      this.deploymentService
        .updateDeployment(
          submitDeployment.projectId,
          submitDeployment.environmentId,
          this.deployment.id,
          submitDeployment
        )
        .subscribe(
          (response) => {
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.SUCCESS,
              message: 'Updated successfully',
            });
            this.router.navigate(['/deploy']);
          },
          (error) => {
            this.formSubmitted = false;
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.ERROR,
              message: error.error,
            });
          }
        );
    } else {
      this.deploymentService
        .createDeployment(
          submitDeployment.projectId,
          submitDeployment.environmentId,
          submitDeployment
        )
        .subscribe(
          (response) => {
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.SUCCESS,
              message: 'Created successfully',
            });
            this.router.navigate(['/deploy']);
          },
          (error) => {
            this.formSubmitted = false;
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.ERROR,
              message: error.error,
            });
          }
        );
    }
  }
}
