<header class="mb-2">
  <nav class="navbar navbar-expand-lg navbar-light bg-light cno-navbar">
    <div class="container-fluid">
      <a class="navbar-brand" (click)="currentMenu = ''" href="#/dashboard"><img
          src="../../../assets/img/news/cloudoor-04.png" class="img-fluid h-100" width="100px" alt="logo" /></a>
      <button class="navbar-toggler" (click)="toggleNavbarCollapsing()" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse justify-content-between" id="navbarSupportedContent"
        [class.collapse]="navbarCollapsed">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 py-2">
          <li class="nav-item hover" (click)="currentMenu = 'apps'" routerLink="/apps"
            [class]="{ active: currentMenu == 'apps' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'apps'" routerLink="/apps">
              Develop
              <i class="bi bi-code-slash ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item hover" (click)="currentMenu = 'deploy'" aria-current="page" routerLink="/deploy"
            [class]="{ active: currentMenu == 'deploy' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'deploy'" aria-current="page" routerLink="/deploy">
              Deploy
              <i class="bi bi-rocket-takeoff ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item hover" (click)="currentMenu = 'deliver'" aria-current="page" routerLink="/deliver"
            [class]="{ active: currentMenu == 'deliver' }">
            <a class="nav-link d-flex justify-content-center align-items-center" (click)="currentMenu = 'deliver'" aria-current="page" routerLink="/deliver">
              Deliver
              <i class="bi bi-truck ml-2" style="font-size: 15px;"></i>
            </a>
          </li>

          <li class="nav-item px-4" *ngIf="
              auth?.hasOrganizationRoles('super_admin') &&
              shareService?.organizationSelected?.subscriptionType.includes(
                'free'
              )
            ">
            <button type="button" class="btn btn-primary upgrade-btn pe-4" (click)="onUpgradePlan()">
              <i class="bi bi-lightning-fill"></i>
              Upgrade
            </button>
          </li>
        </ul>

        <div class="btn-group org-dropdown" dropdown>
          <buttonproviders id="button-disabled-item" dropdownToggle type="button"
            class="dropdown-btn d-flex justify-content-between" aria-controls="dropdown-disabled-item">
            <span style="font-weight: 500">{{
              shareService?.organizationSelected?.name
              }}</span>
            <i class="fa fa-caret-down"></i>
          </buttonproviders>
          <ul id="dropdown-disabled-item" *dropdownMenu class="dropdown-menu" role="menu"
            aria-labelledby="button-disabled-item">
            <li [ngClass]="{
                disabled: shareService?.organizationSelected?.id == org.id
              }" role="menuitem" class="item" *ngFor="let org of shareService.organizations">
              <button class="dropdown-item" [ngClass]="{
                  disabled: shareService?.organizationSelected?.id == org?.id
                }" (click)="onChangeOrganization(org)" href="#">
                {{ truncate(org.name, 30) }}
              </button>
            </li>
          </ul>
        </div>

        <form class="d-flex topbtn">
          <button type="button" (click)="onCommandCnoctl()" class="btn btn-primary link-btn" data-bs-toggle="modal"
            data-bs-target="#right">
            <i class="bi bi-link-45deg"></i>
          </button>

          <button (mouseover)="showDiv()" type="button" [class.person-icon]="this.showMenu"
            class="btn btn-primary link-btn person" (click)="onToggleProfileMenu()">
            <i class="bi bi-person-fill"></i>
          </button>
        </form>
      </div>
    </div>
  </nav>
</header>

<ng-container *ngIf="showMenu">
  <div class="profile-menu" (mouseleave)="hideDiv()">
    <div class="pointing-triangle"></div>

    <div class="user-info">
      <div>
        {{
        currentUser.username ? currentUser.username : currentUser.displayName
        }}
      </div>
      <div>{{ truncate(currentUser.email, 30) }}</div>
    </div>

    <nav class="menu">
      <ul>
        <li (click)="onUpgradePlan()" style="border-bottom: 1px solid #e3eaf5; color: #1cc66d" *ngIf="
            auth?.hasOrganizationRoles('super_admin') &&
            shareService?.organizationSelected.subscriptionType.includes('team')
          ">
          <i class="bi bi-lightning-charge"></i>
          <span>Upgrade your plan</span>
        </li>
        <li (click)="onPorject()">
          <i class="bi bi-box"></i>
          <span>Projects</span>
        </li>
        <li (click)="onMembers()">
          <i class="bi bi-people"></i>
          <span>Members</span>
        </li>
        <li (click)="onCloudProvider()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
          <i class="bi bi-cloud"></i>
          <span>Cloud Providers</span>
        </li>
        <li (click)="onCluster()">
          <!-- *ngIf="auth?.hasOrganizationRoles('super_admin') && shareService?.organizationSelected.name == 'default'"> -->
          <img src="../../../../../../assets/img/cluster.png" alt="" srcset="" style="width: 16px" />
          <span>Clusters</span>
        </li>
        <li (click)="onRegistryClick()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
          <i class="bi bi-pass"></i>
          <span>Registry</span>

        </li>
        <li (click)="onDomain()"
          *ngIf="(auth?.hasOrganizationRoles('super_admin') && !onlyClusterShared) || this.currentUser.email == supportEmail">
          <i class="bi bi-key"></i>
          <span>Domain</span>
        </li>
        <li (click)="onTag()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
          <i class="bi bi-bookmark"></i>
          <span>Tags</span>
        </li>
        <li (click)="onManageNotification()" *ngIf="
            auth?.hasOrganizationRoles('super_admin') &&
            shareService?.organizationSelected.name == 'default'
          ">
          <i class="bi bi-bell"></i>
          <span>Manage Notifications</span>
        </li>

        <li (click)="onQuota()" *ngIf="auth?.hasOrganizationRoles('super_admin')">
          <img src="../../../../../../assets/icons/qta.svg" alt="" srcset="" />
          <span>Quotas</span>
        </li>

        <li (click)="onConfigSAML()" *ngIf="
            auth?.hasOrganizationRoles('super_admin') &&
            shareService?.organizationSelected.subscriptionType.includes(
              subscriptionType
            )
          ">
          <i class="bi bi-lock"></i>
          <span>Config SSO</span>
        </li>

        <li (click)="onOrganization()" *ngIf="
            this.currentUser.email == supportEmail &&
            shareService?.organizationSelected.name == 'default'
          ">
          <i class="bi bi-buildings"></i>
          <span>Organizations</span>
        </li>

        <li (click)="onDiscordCommunity()" style="border-top: 1px solid #e3eaf5">
          <i class="bi bi-discord"></i>
          <span>Discord Community</span>
        </li>

        <li (click)="onSlackCommunity()">
          <i class="bi bi-slack"></i>
          <span>Slack Community</span>
        </li>
        <li (click)="onLogout()">
          <i class="bi bi-power"></i>
          <span>Logout</span>
        </li>
      </ul>
    </nav>
  </div>
</ng-container>
