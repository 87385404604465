import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PipelineInfo } from 'src/app/utils/types';
import * as moment from 'moment';
import { interval,Subscription } from 'rxjs';
var Convert = require('ansi-to-html');
var convert = new Convert();
interface LogEntry {
  duration: string;
  logs: string;
  status: string;
  numero: number;
}

interface Log {
  key: string;
  duration: string;
  logs: any;
  status: string;
  numero: number;

}
@Component({
  selector: 'app-app-log',
  templateUrl: './app-log.component.html',
  styleUrls: ['./app-log.component.scss'],
})
export class AppLogComponent implements OnInit, OnDestroy {
  @Input('ansiToHtml') ansiString: string | undefined;

  // Running

  selectedTab: number = 0;
  private intervalSubscription: Subscription;
  projectID: string = "";
  pipelineId: string = "";
  pipelineInfo: PipelineInfo;
  logsDataTable: Log[] = [];
  loadingLog: boolean = false;
  loadingPipeline: boolean = false;

  pipelineStatus: boolean = false;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // this.isCollapsed[0] = true
  }
  ngOnInit(): void {
    const url: string = this.router.url;
    this.route.queryParams.subscribe((params) => {
      this.pipelineId = params['pipelineId'];
      this.projectID = params['projectId'];
      if (this.pipelineId && this.projectID) {
        this.getPipelineRunInfoById();
        this.intervalSubscription = interval(5000).subscribe(() => {
          if (url.includes('app-log')) {
            this.getPipelineRunInfoById();
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
  getErrorFailedStatus(value: string): boolean {
    if (/fail(?:ed)?|error/i.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  isCollapsed: boolean[] = Array(this.logsDataTable?.length).fill(false);

  toggleCollapse(index: number): void {
    this.isCollapsed[index] = !this.isCollapsed[index];
    this.selectedTab = index;
    for (let i = 0; i < this.isCollapsed.length; i++) {
      if (i !== index) {
        this.isCollapsed[i] = false;
      }
    }
  }
  sortLogsDataTable(response: PipelineInfo) {
    return Object.entries(response?.logs)
      ?.map(([key, value]: [string, LogEntry]) => ({
        key,
        duration: value.duration,
        logs: convert.toHtml(value.logs),
        status: value.status.toLowerCase(),
        numero: value.numero,
      }))
      .sort((a, b) => a.numero - b.numero);
  }
  getPipelineRunInfoById() {
    this.appService.getPipelineRunById(this.pipelineId, this.projectID).subscribe(
        (response: PipelineInfo) => {
          if (response?.status == 'Running' && this.logsDataTable.length > 0) {
            this.pipelineInfo = response;
            this.isCollapsed = Array(this.logsDataTable?.length).fill(false);
            this.logsDataTable = this.sortLogsDataTable(response);
            let getRunningLog = this.logsDataTable?.filter(
              (log) => log.status == 'Running'
            );
            this.isCollapsed[this.logsDataTable?.length - 1] = true;
          } else if (['Succeeded', 'Failed'].includes(response?.status)) {
            this.loadingLog = true;
            this.pipelineInfo = response;
            this.logsDataTable = this.sortLogsDataTable(response);
            this.isCollapsed[this.logsDataTable?.length - 1] = true;
            this.intervalSubscription.unsubscribe();
          } else if (
            (response?.status == "Pending" || response?.status == '' || response?.status == 'Running') &&
            (response.logs == null || this.logsDataTable.length == 0)
          ) {
            this.loadingLog = true;
            this.pipelineInfo = response;
            this.logsDataTable = this.sortLogsDataTable(response);
            let getRunningLog = this.logsDataTable?.filter(
              (log) => log.status == 'Running'
            );
            this.isCollapsed[this.logsDataTable?.length - 1] = true;
          }
          this.loadingLog = false;
          this.loadingPipeline = false;

          if (this.pipelineInfo && this.logsDataTable?.length > 0) {
            setTimeout(() => {
              let index = this.logsDataTable?.length - 1;
              this.updateScroll('pre' + index);
            }, 500);
          }

        },
        (error) => console.log(error.message)
      );
  }

  displayDateForHuman(date: any) {
    if (date) {
      let parseToMoment = moment(date);
      return parseToMoment.fromNow();
    }
  }
  onBack() {
    this.router.navigate(['/app-details'], {
      queryParams: {
        id: this.pipelineInfo.pipelineID,
        projectId: this.projectID,
      },
    });
  }

  updateScroll(elementName) {
    var element = document.getElementById(elementName);
    var main = document.querySelector('.main');
    if (element.scroll){
      element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
    }
    if (main.scroll){
      main.scroll({ top: main.scrollHeight, behavior: 'smooth' });
    }
  }
}
